import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { startOfMonth, endOfMonth } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'FmsTimesheet',
  components: {
    DatePicker,
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.getFmsTimesheetList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsTimesheetList();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      depVsetParam: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      showAlert: false,
      editMode: false,
      project: {
        id: null,
        text: null
      },
      salesAgreement: {
        id: null,
        text: null
      },
      salesAgreementComp: {
        id: null,
        text: null
      },
      salesAgreementCompDtl: {
        id: null,
        text: null
      },
      month: null,
      payload: null,
      firstDayOfTheMonth: null,
      lastDayOfTheMonth: null,
      fmsTimesheetEmployeeData: [],
      fmsTimesheetEmployeeFields: [
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'sa_num',
          label: 'Sales Agmt No.'
        },
        {
          key: 'sales_agreement_comp'
        },
        {
          key: 'employee_name'
        },
        {
          key: 'employee_code'
        },
        {
          key: 'billing_plan_name'
        },
        {
          key: 'data_range',
          label: 'Date Range'
        }
      ]
    };
  },
  validations: {
    project: {
      text: { required }
    },
    salesAgreement: {
      text: { required }
    },
    month: { required }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditEmployeeTimesheetDetails();
        }
      }
    });
  },
  methods: {
    getFmsTimesheetEmployeeData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _limit: this.perPage,
          _page: this.currentPage - 1,
          prj_id: this.project.id,
          sales_agreement_hdr_id: this.salesAgreement.id,
          sales_agreement_comp_id: this.salesAgreementComp.id,
          sales_agreement_comp_dtl_id: this.salesAgreementCompDtl.id,
          start_date: commonHelper.formattedDate(this.firstDayOfTheMonth),
          end_date: commonHelper.formattedDate(this.lastDayOfTheMonth)
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTimesheet/getFmsTimesheetEmployeeData', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.fmsTimesheetEmployeeData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
      }
    },
    customMonthFunction() {
      this.firstDayOfTheMonth = startOfMonth(new Date(this.month));
      this.lastDayOfTheMonth = endOfMonth(new Date(this.month));
    },
    addEditEmployeeTimesheetDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const timesheetArray = this.fmsTimesheetEmployeeData.map(item => {
          return {
            sales_agmt_comp_dtl_id: item.sales_agreement_comp_dtl_id,
            sales_agmt_comp_id: item.sales_agreement_comp_id,
            sales_agmt_hdr_id: item.sales_agmt_hdr_id,
            employee_name: item.employee_name,
            employee_code: item.employee_code,
            start_date: commonHelper.formattedDate(this.firstDayOfTheMonth),
            end_date: commonHelper.formattedDate(this.lastDayOfTheMonth)
          };
        });
        const payload = {
          timesheets: timesheetArray
        };
        this.$store
          .dispatch('fmsTimesheet/addEditEmployeeTimesheetDetails', payload)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.editMode = false;
              this.responseMsg = response.data.message;
              this.getFmsTimesheetEmployeeData();
            } else {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.parent_value_set_id = this.project.id;
        this.depVsetParam = this.$store.state.auth.userId;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.parent_value_set_id = this.salesAgreement.id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.parent_value_set_id = this.salesAgreementComp.id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.salesAgreementComp = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.salesAgreementCompDtl = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = { id: null, text: null };
        this.salesAgreement = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.salesAgreement = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.salesAgreementCompDtl = { id: null, text: null };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearFilters() {
      this.fmsTimesheetEmployeeData = [];
      this.totalRows = null;
      this.project = { id: null, text: null };
      this.salesAgreement = { id: null, text: null };
      this.salesAgreementComp = { id: null, text: null };
      this.salesAgreementCompDtl = { id: null, text: null };
      this.month = null;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
